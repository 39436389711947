html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 96%;
}
html, body {
    margin: 0;
    height: 100%; 
    min-height: 100%;
}
body {
    margin: 0;
    padding: 0;
    overflow: auto;
    color: #979797;
    line-height: 1;
}
::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb {
    background: #303F9F; 
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
    background: #303F9F; 
}
.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}
.p-shadow-6 {
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
}
.p-float-label {
    margin-bottom: 17px;
    width:100% !important;
}
.p-datatable-tbody > tr > td {
    padding: 3px !important;
}
.p-paginator-bottom {
    padding: 0px !important;
}
.p-dialog-header {
    padding-bottom: 12px !important;    
}
.p-inputtext {
    width: 100%;
}
.p-fieldset-legend {
    padding: 0.3rem !important;
    background: inherit !important;
    border: none !important;
}
.p-fieldset-content {
    padding-top: 6px !important;
}
.p-component {
    font-family: -apple-system, Roboto, Helvetica, Arial, sans-serif !important;
}
.p-fileupload-row {
    display: block !important
}
.p-fileupload-files {
    margin-top: -30px !important;
}
.p-fileupload-buttonbar {
    text-align: center !important;
}
.p-fileupload-buttonbar {
    padding: 0.3rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.required_input_error {
    border: 3px solid red !important;
}
.p-toast {
    width: 510px;
}
/*
.p-button-label {
    text-align: left;
}
*/
.p-selectable-row:hover {
    background: #FCFCFC !important;
}
.p-fileupload-content {
    min-height: 204px;
}
.p-progressbar {
    height: 9px;
}
.p-card-title {
    text-align: left;
}
.p-card-subtitle {
    text-align: left;
    margin-left: 15px;
}
.p-card-content {
    padding-bottom: 0px !important;
}
.tabview-header-icon i, .tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}
.tabview-header-icon span {
    margin: 0 .5rem;
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}
.padding-min {
    padding: 3px !important;
}
.padding-med {
    padding: 9px !important;
}
.padding-max {
    padding: 12px !important;
}
.padding-max_1 {
    padding: 15px !important;
}
.padding-max_2 {
    padding: 18px !important;
}
.padding-zero {
    padding: 0px !important;
}
.p-inputtext {
    padding: 0.6rem !important;
}
.main-toolbar {
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
/*    width: 97%;
    margin: 0 auto; 
    margin-bottom: 9px; */
    border-radius: unset;
    background-image: -webkit-linear-gradient(left, #2764B6, #303BB5);
}
.field > label {
    font-weight: 600 !important;
    color: #000000 !important;
    font-size: 15px !important;
}
.p-scrollpanel-content {
    overflow-x: hidden;
}
@media screen and (max-width: 480px) {
    .responsive-dialog-small-small {
        width: 81%;
    }
    .responsive-dialog-small {
        width: 81%;
    }
    .responsive-dialog-small-med {
        width: 84%;
    }
    .responsive-dialog-med {
        width: 90%;
    }
    .responsive-dialog-big {
        width: 99.5%;
    }
}
@media screen and (min-width: 800px) {
    .responsive-dialog-small-small {
        width: 60%;
    }
    .responsive-dialog-small {
        width: 63%;
    }
    .responsive-dialog-small-med {
        width: 80%;
    }
    .responsive-dialog-med {
        width: 80%;
    }
    .responsive-dialog-big {
        width: 90%;
    }
}
@media screen and (min-width: 1200px) {
    .responsive-dialog-small-small {
        width: 40%;
    }
    .responsive-dialog-small {
        width: 48%;
    }
    .responsive-dialog-small-med {
        width: 60%;
    }
    .responsive-dialog-med {
        width: 70%;
    }
    .responsive-dialog-big {
        width: 84%;
    }
}
@media screen and (min-width: 1400px) {
    .responsive-dialog-small-small {
        width: 20%;
    }
    .responsive-dialog-small {
        width: 30%;
    }
    .responsive-dialog-small-med {
        width: 36%;
    }
    .responsive-dialog-med {
        width: 51%;
    }
    .responsive-dialog-big {
        width: 80%;
    }
}
@media screen and (max-width: 480px) {
    .login-body .login-panel {
        left: 0;
        margin-left: 3%;
        width: 94%;
    }
}
.p-dialog {
    background-color: #F4F4F7;
}
.p-dialog-header {
    background-color: #F4F4F7;
}
.p-dialog-content {
    background-color: #F4F4F7;
}
.invoice_left_div {
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    height: 80.4vh;
    width: 50%;
    float: left;
}
.invoice_right_div {
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    height: 80.4vh;
    width: 49%;
    float: right;
    padding-left: 12px;
}
.mfa_invoice_client {
    padding-top: 18px !important;
    padding-left: 45px !important;
}
.mfa_invoice_no_client {
    color: #FF0000;
    padding-top: 30%;
    text-align: center;
    font-weight: 600;
}
.align_left {
    text-align: left;
}
.align_right {
    text-align: right;
}
.align_center {
    text-align: center;
}
.p-divider-content {
    background-color: #F4F4F7 !important;
}
.invoice_up_div {
    margin-top: -6px;
    border: 0px solid #D3D3D3;
    border-radius: 6px;
}
.invoice_operations_button {
    width: 100%;
    height: 48px;
    padding-left: 30px;
}
.p-button-sm {
    height: 37px !important;
}
.watermark {
    opacity: 0.3;
    z-index: 99;
    font-size: 36px;
    padding-top: 105px;
}
#browse_inventory > .p-button-label {
    text-align: center;
}
.groups_list {
    height: 100px;
    text-align: center;
    line-height: 100px;
    background-color: lightskyblue;
    cursor: pointer;
}
.groups_list_name {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    width: 90%;
    font-weight: 600;
}
.groups_list .p-card  {
    padding: 0px;
}
.groups_list .p-card  {
    padding: 0px;
}
.groups_list .p-card-body {
    padding: 0px;
}
.groups_list .p-card-content {
    padding: 0px;
}
.groups_list_sel {
    text-align: center;
    background-color: coral;
}
.groups_list_name_sel {
    display: inline-block;
    vertical-align: middle;
    line-height: 3.1;
    width: 90%;
    font-weight: 600;
    cursor: pointer;
}
.groups_list_sel .p-card {
    padding: 0px;
}
.groups_list_sel .p-card {
    padding: 0px;
}
.groups_list_sel .p-card-body {
    padding: 0px;
}
.groups_list_sel .p-card-content {
    padding: 0px;
}
.groups_list_sel .p-card-body {
    padding-bottom: 0px !important;
}
.groups_list_sel_white {
    background-color: white;
}
.items_list_mobile .p-card-content {
    padding: 0px;
}
.items_list_mobile .p-card {
    font-size: 1rem;
    margin-bottom: 0px;
}
.items_list_mobile .p-card-title {
    font-size: 1rem;
    margin-bottom: 0px;
}
.items_list_mobile_details {
    display: inline-block;
    vertical-align: middle;
    line-height: 3.1;
    width: 99%;
}
.field > div > label {
    font-weight: 600 !important;
    color: #000000 !important;
    font-size: 15px !important;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.p-dropdown {
    height: 39px;
}
@media screen and (max-width: 480px) {
    .p-dropdown-pannel {
        max-width: 360px;
        margin-left: 23px;
    }
}
.vertical_center {
    display: flex;
    height: 75vh;
    align-items: center;
}
.invoices_rfd_row {
    background-color: #00FFB3 !important;
}
.invoices_deleted_row {
    background-color: #FF7C00 !important;
}
.h0 {
    height: 0px;
}
.expense {
    color: #FFFFFF;
}
.expense:hover {
    color: #000000;
}
h5 {
    font-size: 1rem;
}
.p-inputgroup-addon {
    height: 38px;
}
.p-inputgroup input {
    height: 38px;
}
